<template>
  <ImageSelector
    resourceName="artist"
    :currentImage="currentImage"
    :images="images"
    :loadingImages="loadingImages"
    :customFileFieldName="customFileFieldName"
  />
</template>

<script>
import ImageSelector from '../general/components/ImageSelector.vue'
import api from '../store/API'
import { debounce } from '@/general/utils/debounce'

const nameElement = document.querySelector('#artist_name')

export default {
  components: {
    ImageSelector,
  },
  props: ['currentImage', 'customFileFieldName'],
  data() {
    return {
      name: nameElement.value,
      images: [],
      loadingImages: false,
    }
  },
  mounted() {
    nameElement.addEventListener('input', () => {
      this.name = nameElement.value
      this.loadingImages = true
      this.debounceFetchImages()
    })
    this.fetchImages()
  },
  methods: {
    async fetchImages() {
      if (!this.name) {
        this.loadingImages = false
        this.images = null
        return
      }

      this.loadingImages = true

      const response = await api.get(`/enrichment/artists/images`, { params: { artist_name: this.name } })

      this.images = response.data
      this.loadingImages = false
    },
    debounceFetchImages: debounce(function () {
      this.fetchImages()
    }, 1000), // High debounce due to rate limiting on the images provider API.
  },
}
</script>
