<template>
  <tr>
    <td class="fit">
      <i v-if="isBlacklisted" class="fa fa-trophy blacklisted" />
    </td>
    <td>
      <a :href="signupLink">{{ profile.name }}</a>
    </td>
    <td>
      <PhoneToLink :number="profile.number" />
    </td>
    <td>{{ profile.age }} jaar</td>
    <td>{{ receivedAtFormatted }}</td>
    <td>
      <span v-if="additionalAnswers && additionalAnswers.length" class="additional-answers">
        <span v-for="answer in additionalAnswers" :key="answer.id" class="answer"> {{ answer.answer }}</span>
      </span>
    </td>
    <td class="signup-actions">
      <button v-if="selectedAt && !contactedAt" class="btn btn-with-icon btn-sm" @click="contactPerson">
        Selecteer voor uitzending
      </button>
      <button v-if="contactedAt || selectedAt" class="btn btn-with-icon btn-sm" @click="clearPerson">
        <i class="fa fa-times avoid-clicks" />
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { format, parseISO } from 'date-fns'
import PhoneToLink from '@/general/components/PhoneToLink.vue'

export default {
  name: 'SignUpRow',
  components: {
    PhoneToLink,
  },
  props: ['id', 'profile', 'isBlacklisted', 'receivedAt', 'selectedAt', 'contactedAt', 'momentId', 'additionalAnswers'],
  computed: {
    signupLink() {
      return `/production/sign_ups/${this.id}`
    },
    receivedAtFormatted() {
      return format(parseISO(this.receivedAt), 'dd/MM HH:mm:ss')
    },
  },
  methods: {
    ...mapActions({
      clear: 'SignUps/clearPerson',
      contact: 'SignUps/contactPerson',
    }),
    contactPerson() {
      this.contact({ momentId: this.momentId, signUpId: this.id })
    },
    clearPerson() {
      this.clear({ momentId: this.momentId, signUpId: this.id })
    },
  },
}
</script>

<style scoped lang="scss">
.signup-actions {
  text-align: end;
  justify-content: end;
}

.additional-answers {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  .answer {
    background: #f0f0f0;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
