<template>
  <tr>
    <td class="fit">
      <i v-if="profile.isBlacklisted" class="fa fa-trophy blacklisted" />
    </td>
    <td>
      <a :href="profileLink">{{ profile.name }}</a>
    </td>
    <td><PhoneToLink :number="profile.number" /></td>
    <td>{{ profile.age }} jaar</td>
    <td class="fit">{{ receivedAtFormatted }}</td>
    <td class="detection-actions">
      <button v-if="selectedAt" class="btn btn-with-icon btn-sm" @click="clearPerson">
        <i class="fa fa-times avoid-clicks" />
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { format, parseISO } from 'date-fns'
import PhoneToLink from '@/general/components/PhoneToLink.vue'

export default {
  name: 'ForbiddenWordRow',
  components: {
    PhoneToLink,
  },
  props: ['id', 'profile', 'receivedAt', 'selectedAt', 'contactedAt', 'forbiddenWordId'],
  computed: {
    receivedAtFormatted() {
      return format(parseISO(this.receivedAt), 'dd/MM HH:mm:ss')
    },
    profileLink() {
      return `/production/profiles/${this.profile.id}`
    },
  },
  methods: {
    ...mapActions({
      clear: 'ForbiddenWord/clearPerson',
      select: 'ForbiddenWord/selectWinner',
    }),
    clearPerson() {
      this.clear({ forbiddenWordId: this.forbiddenWordId, detectionId: this.id })
    },
  },
}
</script>

<style scoped lang="scss">
.detection-actions {
  text-align: end;
  justify-content: end;
}
</style>
