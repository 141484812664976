<template>
  <div class="improve-audio">
    <div class="player">
      <Transition mode="out-in">
        <slot :url="activeUrl" :filename="activeFilename" :improved="!inOriginalMode" />
      </Transition>
    </div>
    <div class="button-wrapper">
      <button :class="{ active: !inOriginalMode }" :disabled="isLoading" @click="toggle()">
        <DarioTooltip v-if="state === 'idle'">
          <img class="doria-logo" src="../../../assets/images/doria_magic.png" />
          <template #tooltip-content>
            {{ mode === 'original' ? 'Verbeter audio met AI' : 'Terug naar origineel' }}
          </template>
        </DarioTooltip>

        <i v-if="state === 'loading'" class="fa fa-spinner fa-spin" />
      </button>
    </div>
  </div>
</template>

<script>
import api from '@/store/API'
import DarioTooltip from './DarioTooltip.vue'

export default {
  components: { DarioTooltip },
  props: ['originalUrl', 'improvedUrl', 'filename', 'improveUrl', 'improving'],
  data() {
    return {
      mode: 'original',
      state: 'idle',
    }
  },
  computed: {
    inOriginalMode() {
      return this.mode === 'original'
    },
    isLoading() {
      return this.state === 'loading'
    },
    activeUrl() {
      return this.inOriginalMode ? this.originalUrl : this.improvedUrl
    },
    activeFilename() {
      return this.inOriginalMode ? this.filename : `improved-${this.filename}`
    },
  },
  watch: {
    improving() {
      if (this.improving) {
        // Externally triggered improvement.
        this.state = 'loading'
      }
    },
    improvedUrl: {
      handler() {
        if (this.improvedUrl) {
          this.state = 'idle'
          this.mode = 'improved'
        }
      },
      immediate: true,
    },
  },
  methods: {
    toggle() {
      if (this.inOriginalMode && !this.improvedUrl) {
        this.requestImprovedAudio()
      } else {
        this.mode = this.inOriginalMode ? 'improved' : 'original'
      }
    },
    async requestImprovedAudio() {
      this.state = 'loading'
      await api.post(this.improveUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.improve-audio {
  display: flex;
  width: 100%;

  .player {
    flex: 1 1 0%;
    margin-right: 0.5em;
  }

  .button-wrapper {
    background: white;
    border-radius: 0.75em;
    padding: 0.4em 0.5em;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

    button {
      background: #f9f9f9;
      width: 100%;
      height: 100%;
      border-radius: 0.5em;
      padding: 0.5em;
      display: flex;
      align-content: center;
      justify-content: center;
      text-align: left;
      flex-wrap: wrap;
      border: none;
      font-size: 1.2em;
      width: 4em;

      transition: all 300ms;

      &:hover {
        background: #f3f2f2;
      }

      &.active {
        background: #5ebd5e;
        color: white;
      }

      .doria-logo {
        width: 100%;
      }
    }
  }
}

.v-move,
.v-enter-active,
.v-leave-active {
  transition: 100ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(100px);
}
</style>
