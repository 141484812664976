<template>
  <ImageSelector
    resourceName="track"
    :currentImage="currentImage"
    :images="images"
    :loadingImages="loadingImages"
    :customFileFieldName="customFileFieldName"
  />
</template>

<script>
import ImageSelector from '../general/components/ImageSelector.vue'

export default {
  components: {
    ImageSelector,
  },
  props: ['artist', 'title', 'currentImage', 'customFileFieldName'],
  data() {
    return {
      images: [],
      loadingImages: false,
    }
  },
  mounted() {
    this.fetchImages()
  },
  methods: {
    async fetchImages() {
      this.loadingImages = true

      try {
        const query = encodeURIComponent(`${this.artist} - ${this.title}`)
        const response = await fetch(`https://itunes.apple.com/search?term=${query}&country=NL&limit=4&entity=song`) // limit 4 means 5, in itunes land
        const data = await response.json()

        this.images = data.results.map((track) => track.artworkUrl100.replace('100x100', '1400x1400'))
      } catch (error) {
        console.error(error)
        this.images = []
      } finally {
        this.loadingImages = false
      }
    },
  },
}
</script>
