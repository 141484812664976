<template>
  <ApiModal @close="$emit('close')">
    <template #header>
      <h4>
        <img class="doria-logo" src="../../assets/images/doria.png" />
        {{ broadcastedAt }} - {{ broadcast.title }}
      </h4>
    </template>
    <div class="panel-body">
      <div class="actions">
        <a href="#" @click.prevent="showAll = !showAll">{{
          showAll ? 'Toon enkel fragmenten mét samenvatting' : 'Toon ook fragmenten zonder samenvatting'
        }}</a>
      </div>
      <div v-if="loading">
        <i class="fa fa-spinner fa-spin" />
        Samenvattingen laden…
      </div>
      <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
      <div v-for="(recordings, hour) in recordingsPerHour" :key="hour">
        <h4>{{ hour }}u00</h4>

        <DoriaSummaryRecording
          v-for="recording in recordings"
          :key="recording.id"
          :recording="recording"
          @update="updateSummary($event)"
        />
      </div>
    </div>
  </ApiModal>
</template>

<script>
import api from '@/store/API'

import { format } from 'date-fns'
import nlDateLocale from 'date-fns/locale/nl'

import ApiModal from '@/general/components/ApiModal.vue'
import DoriaSummaryRecording from './DoriaSummaryRecording.vue'
import { airbrake } from '@/general/airbrake'

export default {
  name: 'DoriaSummaryPopup',
  components: {
    ApiModal,
    DoriaSummaryRecording,
  },
  props: {
    broadcast: {
      type: Object,
      required: true,
    },
  },
  emits: ['close'],
  data() {
    return {
      programRecordings: [],
      loading: true,
      errorMessage: null,
      showAll: false,
    }
  },
  computed: {
    shownProgramRecordings() {
      return this.showAll
        ? this.programRecordings
        : this.programRecordings.filter((programRecording) => programRecording.summary)
    },

    recordingsPerHour() {
      return this.shownProgramRecordings.reduce((acc, programRecording) => {
        const hour = new Date(programRecording.starts_at).getHours()
        acc[hour] = acc[hour] || []
        acc[hour].push(programRecording)
        return acc
      }, {})
    },
    broadcastedAt() {
      return format(new Date(this.broadcast.starts_at), 'eeee dd MMM HH:mm', { locale: nlDateLocale })
    },
  },
  async mounted() {
    try {
      const { program_recordings } = (
        await api.get(`/production/broadcasts/${this.broadcast.id}/program_recordings.json`)
      ).data

      this.programRecordings = program_recordings
    } catch (error) {
      this.errorMessage = `Er is iets foutgegaan bij het ophalen van de samenvatting voor deze uitzending. Ververs de pagina en probeer het opnieuw. (Technische details: ${error.message})`
      airbrake?.notify(error)
    } finally {
      this.loading = false
    }
  },
  methods: {
    updateSummary(recording) {
      const index = this.programRecordings.findIndex((programRecording) => programRecording.id === recording.id)
      this.programRecordings.splice(index, 1, recording)

      api.put(`/production/broadcasts/${this.broadcast.id}/program_recordings/${recording.id}.json`, {
        program_recording: {
          summary: recording.summary,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.doria-logo {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.actions {
  font-size: 11px;
  float: right;
}
</style>
