<template>
  <div class="audio-player" @click.prevent="togglePlay">
    <div class="play-button">
      <i :class="isPlaying ? 'fa fa-pause' : 'fa fa-play'" />
    </div>
    <span class="time-indicator">{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</span>
    <div class="actions">
      <slot name="actions" />
    </div>
    <audio
      ref="audio"
      :src="src"
      style="display: none"
      @timeupdate="currentTime = $event.target.currentTime"
      @loadedmetadata="duration = $event.target.duration"
      @ended="handleEnded"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'SimpleAudioPlayer',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
    }
  },
  mounted() {
    this.audio = this.$refs.audio

    this.audio.addEventListener('play', () => {
      this.isPlaying = true
      this.globallyMarkAsPlaying({ playerId: this.src, pauseFunction: () => this.stop() })
    })
    this.audio.addEventListener('pause', () => {
      this.isPlaying = false
      this.globallyMarkAsStopped({ playerId: this.src })
    })
  },
  beforeUnmount() {
    this.stop()
  },
  methods: {
    ...mapActions({
      globallyMarkAsPlaying: 'AudioPlayers/markAsPlaying',
      globallyMarkAsStopped: 'AudioPlayers/markAsStopped',
    }),
    togglePlay() {
      if (this.isPlaying) {
        this.audio.pause()
      } else {
        this.audio.play()
      }
    },
    stop() {
      this.audio.pause()
      this.audio.currentTime = 0
    },
    handleEnded() {
      this.isPlaying = false
      this.currentTime = 0
    },
    formatTime(value) {
      const minutes = Math.floor(value / 60)
      const seconds = Math.floor(value % 60)
        .toString()
        .padStart(2, '0')
      return `${minutes}:${seconds}`
    },
  },
}
</script>

<style scoped>
.audio-player {
  display: flex;
  gap: 10px;
  padding: 0px 20px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f3f4;
  border-radius: 30px;
  height: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.play-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.time-indicator {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  margin-right: 10px;
}

.actions {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #333;
}
</style>
