<template>
  <label>
    <n-switch v-model:value="checked" />
    {{ label }}
  </label>
  <input type="hidden" :name="fieldName" :value="checked ? 1 : 0" />
</template>

<script>
import { NSwitch } from 'naive-ui'

export default {
  components: {
    NSwitch,
  },
  props: ['label', 'initialValue', 'fieldName'],
  data() {
    return {
      checked: this.initialValue,
    }
  },
}
</script>

<style scoped>
div {
  margin-right: 6px;
}
</style>
