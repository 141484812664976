<template>
  <div class="hooks">
    <div
      v-for="result in searchResults"
      :key="result.trackId"
      class="hook"
      :class="{ selected: selectedResult === result }"
      @click="selectResult(result)"
    >
      <div class="meta">
        <div>
          <input type="radio" class="check" :checked="selectedResult === result" />
        </div>
        <div class="line-clamp-3 title" :title="result.trackName">{{ result.trackName }}</div>
        <div class="line-clamp-2 artist" :title="result.artistName">{{ result.artistName }}</div>
        <div class="line-clamp-2 album" :title="result.collectionName">{{ result.collectionName }}</div>
      </div>

      <SimpleAudioPlayer :src="result.previewUrl" @click.stop="" />
    </div>

    <div class="hook" :class="{ selected: isCustom }" @click="selectCustom">
      <div class="meta">
        <div>
          <input type="radio" class="check" :checked="isCustom" />
        </div>
        <div class="line-clamp-3 title">Custom Hook</div>
      </div>
      <input
        ref="customFileElement"
        type="file"
        :name="customFileFieldName"
        @change="customFile = $event.target.files[0]"
      />
      <SimpleAudioPlayer :src="customFileUrl" />
    </div>
  </div>
  <input v-if="!isCustom" type="hidden" :name="fieldName" :value="selectedResult?.previewUrl" />
</template>

<script>
import SimpleAudioPlayer from '../general/components/SimpleAudioPlayer.vue'

export default {
  components: {
    SimpleAudioPlayer,
  },
  props: ['currentHook', 'currentIsCustom', 'artist', 'title', 'fieldName', 'customFileFieldName'],
  data() {
    return {
      searchResults: null,
      selectedResult: null,
      isCustom: this.currentIsCustom,
      customFile: null,
    }
  },

  computed: {
    customFileUrl() {
      if (this.customFile) {
        return URL.createObjectURL(this.customFile)
      }
      if (this.currentIsCustom) {
        return this.currentHook
      }
      return null
    },
  },
  watch: {
    selectedResult() {
      // Update Apple Music URL in parent form.
      document.querySelector('#track_apple_music_url').value = this.selectedResult?.trackViewUrl || null
    },
  },
  async mounted() {
    await this.search()
  },
  methods: {
    async search() {
      this.searchResults = null

      try {
        const query = encodeURIComponent(`${this.artist} - ${this.title}`)
        const response = await fetch(`https://itunes.apple.com/search?term=${query}&country=NL&limit=4&entity=song`)
        const data = await response.json()

        this.searchResults = data.results
        this.selectedResult = this.searchResults.find((result) => result.previewUrl === this.currentHook) || null
      } catch (error) {
        console.error(error)
        this.searchResults = []
      }
    },
    selectResult(result) {
      this.isCustom = false
      this.selectedResult = result
    },
    selectCustom() {
      this.isCustom = true
      this.selectedResult = null
    },
  },
}
</script>

<style lang="scss" scoped>
.hooks {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 1rem;
}

.hook {
  background: #fafafa;
  border: 1px solid #ececec;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 2rem;

  transition: background 150ms;

  cursor: pointer;

  &.selected {
    border-color: #007bff;
    background: #f0f8ff;
  }

  &:hover:not(.selected) {
    background: #f4f4f4;
  }

  .meta {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;

    .check {
      width: 16px;
      height: 16px;
      margin-bottom: 1rem;
    }

    .title {
      font-weight: bold;
    }

    .album {
      color: #999;
      font-size: 12px;
    }
  }

  .audio-player {
    background: white;
  }
}
</style>
