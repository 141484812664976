<template>
  <div v-if="editor" class="editor" :class="{ 'editor-loading': loading }">
    <div class="editor__header">
      <DropdownButton icon="text-height" buttonClass="editor-dropdown-button">
        <DropdownMenuItem title="Klein" style="font-size: x-small" @click="setFontSize('x-small')" />
        <DropdownMenuItem title="Normaal" @click="setFontSize(false)" />
        <DropdownMenuItem title="Groot" style="font-size: medium" @click="setFontSize('medium')" />
        <DropdownMenuItem title="Groter" style="font-size: large" @click="setFontSize('large')" />
        <DropdownMenuItem title="Gigantisch" style="font-size: x-large" @click="setFontSize('x-large')" />
      </DropdownButton>

      <div class="editor-divider" />

      <MenuItem title="Bold" icon="bold" :isActive="isBold" @click="toggleBold" />
      <MenuItem title="Italic" icon="italic" :isActive="isItalic" @click="toggleItalic" />
      <MenuItem title="Underline" icon="underline" :isActive="isUnderlined" @click="toggleUnderline" />

      <div class="editor-divider" />

      <MenuItem title="Undo" icon="reply" @click="undo" />
      <MenuItem title="Redo" icon="share" @click="redo" />

      <div class="editor-divider" />

      <MenuItem title="Link" icon="link" @click="link" />
      <MenuItem title="Remove Link" icon="unlink" @click="unlink" />

      <div class="editor-divider" />

      <MenuItem title="Clear format" icon="eraser" @click="clearFormatting" />

      <div class="editor-divider" />
      <MenuItem
        className="black"
        title="Zwarte Tekst"
        icon="font"
        :isActive="isColorActive(colors.black)"
        @click="setFontColor(colors.black)"
      />
      <MenuItem
        className="red"
        title="Rode Tekst"
        icon="font"
        :isActive="isColorActive(colors.red)"
        @click="setFontColor(colors.red)"
      />
      <MenuItem
        className="orange"
        title="Oranje Tekst"
        icon="font"
        :isActive="isColorActive(colors.orange)"
        @click="setFontColor(colors.orange)"
      />
      <MenuItem
        className="green"
        title="Groene Tekst"
        icon="font"
        :isActive="isColorActive(colors.green)"
        @click="setFontColor(colors.green)"
      />

      <div class="editor-divider" />

      <MenuItem title="Links uitlijnen" icon="align-left" :isActive="isAligned('left')" @click="alignText('left')" />
      <MenuItem title="Centreren" icon="align-center" :isActive="isAligned('center')" @click="alignText('center')" />
      <MenuItem
        title="Rechts uitlijnen"
        icon="align-right"
        :isActive="isAligned('right')"
        @click="alignText('right')"
      />
      <MenuItem title="Uitvullen" icon="align-justify" :isActive="isAligned('justify')" @click="alignText('justify')" />

      <div class="editor-divider" />

      <MenuItem title="Bullet list" icon="list-ul" :isActive="isBulletList" @click="toggleBulletList" />
      <MenuItem title="Ordered list" icon="list-ol" :isActive="isOrderedList" @click="toggleOrderedList" />

      <div class="editor-divider" />

      <MenuItem title="Plak" icon="clipboard" @click="pasteTextFromClipboard" />
      <slot />
    </div>
    <EditorContent class="editor__content" :editor="editor" />
  </div>
</template>

<script>
import { toRaw } from 'vue'
import TextStyle from '@tiptap/extension-text-style'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'
import * as Y from 'yjs'
import { HocuspocusProvider, HocuspocusProviderWebsocket } from '@hocuspocus/provider'
import DropCursor from '@tiptap/extension-dropcursor'
import Document from '@tiptap/extension-document'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Italic from '@tiptap/extension-italic'
import ListItem from '@tiptap/extension-list-item'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import History from '@tiptap/extension-history'
import Text from '@tiptap/extension-text'
import Link from '@tiptap/extension-link'

import { OrderedList } from './nodes/orderedList'
import { BulletList } from './nodes/bulletList'
import { MessageNode } from './nodes/message'

import { FontSize } from './extensions/fontSize'
import { CustomColor } from './extensions/color'
import { PasteHandler } from './extensions/pasteHandler'
import { DropHandler } from './extensions/dropHandler'

import { FontMark } from './marks/font'
import { CustomBold } from './marks/bold'

import { Editor, EditorContent } from '@tiptap/vue-3'
import DropdownButton from '../DropdownButton.vue'
import DropdownMenuItem from './DropdownMenuItem.vue'
import MenuItem from './MenuItem.vue'
import { mapGetters, mapState } from 'vuex'

// This might be more suited in global state
let wsProviderInstance
const wsProvider = () => {
  if (!wsProviderInstance) {
    wsProviderInstance = new HocuspocusProviderWebsocket({
      url: `${window.userConfig.darioSocketUrl}wysiwyg`,
    })
  }

  // Since we're checking the connection out,
  // we should ensure it tries to connect if it isn't already
  wsProviderInstance.shouldConnect = true

  return wsProviderInstance
}

export default {
  components: {
    EditorContent,
    DropdownButton,
    MenuItem,
    DropdownMenuItem,
  },
  props: ['modelValue', 'identifier', 'loading'],
  data() {
    return {
      editor: null,
      editorConfig: {
        extensions: [
          FontSize,
          FontMark,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          Underline.configure({
            types: ['heading', 'paragraph'],
          }),
          CustomColor,
          CustomBold,
          TextStyle,
          BulletList,
          OrderedList,
          DropCursor,
          Document,
          HardBreak,
          Heading,
          HorizontalRule,
          Italic,
          ListItem,
          Paragraph,
          Strike,
          Text,
          Link,
        ],
      },
      emitAfterOnUpdate: false,
      collabProvider: null,
      collabField: 'default',
      collabDataEmpty: false,
      lastReceivedContent: null,
      colors: {
        black: '#555',
        red: '#e66454',
        orange: '#f4b04f',
        green: '#5ebd5e',
      },
    }
  },
  computed: {
    isBold() {
      return this.editor.isActive('bold')
    },
    isItalic() {
      return this.editor.isActive('italic')
    },
    isUnderlined() {
      return this.editor.isActive('underline')
    },
    isColorActive() {
      return (color) => this.editor.isActive('colors', color)
    },
    isAligned() {
      return (alignment) => this.editor.isActive('textAlign', alignment)
    },
    isBulletList() {
      return this.editor.isActive('bulletList')
    },
    isOrderedList() {
      return this.editor.isActive('orderedList')
    },
    isCollaborative() {
      return this.identifier && window.userConfig.features.scenarioCollaboration
    },
    ...mapState('Users', { user: 'current' }),
    ...mapGetters('Users', ['userColor']),
  },
  watch: {
    modelValue(val) {
      // this is to make sure that the content is not updated when the change is triggered from this component
      if (this.emitAfterOnUpdate) {
        this.emitAfterOnUpdate = false
        return
      }

      if (!this.editor?.commands) {
        return
      }

      // When we're doing collaborive editing, our source of truth is Yjs,
      // so unless our collab data is empty, we should not update the content
      if (this.isCollaborative && !this.collabDataEmpty) {
        this.lastReceivedContent = val
        return
      }

      this.collabDataEmpty = false
      const content = this.transformHTML(val)
      this.editor.commands.setContent(content)
    },
  },
  mounted() {
    if (this.isCollaborative) {
      this.setupCollaboritiveEditing()
    } else {
      this.setupLocalEditing()
    }

    this.editorConfig.extensions.push(PasteHandler)
    this.editorConfig.extensions.push(DropHandler)
    this.editorConfig.extensions.push(MessageNode)

    this.editor = new Editor(toRaw(this.editorConfig))
  },
  beforeUnmount() {
    this.editor?.destroy()
    this.collabProvider?.destroy()
  },
  methods: {
    setupCollaboritiveEditing() {
      const yDoc = new Y.Doc()

      this.collabProvider = new HocuspocusProvider({
        websocketProvider: wsProvider(),
        token: window.userConfig.jwtToken, // IMO this should be coupled on the provider
        name: this.identifier,
        document: yDoc,
        onSynced: () => {
          const fragment = yDoc.getXmlFragment(this.collabField)
          if (fragment.length == 0) {
            const originalContent = this.transformHTML(this.lastReceivedContent)
            if (originalContent.length) {
              this.editor.commands.setContent(originalContent)
            } else {
              // Maybe the content isn't loaded yet. Mark collab as empty so we can fill it when data comes in
              this.collabDataEmpty = true
            }
          }
        },
      })

      yDoc.on('update', (_, origin) => {
        const ownUpdate = !origin.peerId
        this.emitAfterOnUpdate = true

        this.$nextTick(() => {
          this.$emit('update:modelValue', this.editor.getHTML(), { ownUpdate })
        })
      })

      this.editorConfig.extensions.push(
        Collaboration.configure({
          document: toRaw(this.collabProvider.document),
          field: this.collabField,
        }),
        CollaborationCursor.configure({
          provider: toRaw(this.collabProvider),
          user: {
            name: this.user.name,
            color: this.userColor,
          },
        })
      )
    },
    setupLocalEditing() {
      this.editorConfig.extensions.push(History)
      this.editorConfig.content = this.transformHTML()
      this.editorConfig.onUpdate = ({ editor }) => {
        this.emitAfterOnUpdate = true
        this.$emit('update:modelValue', editor.getHTML(), { ownUpdate: true })
      }
    },
    transformHTML(value) {
      const tmp = document.createElement('div')
      tmp.innerHTML = value || this.modelValue
      for (const child of tmp.children) {
        if (child.textContent === '') {
          // remove unused spans, b-tags, br
          child.innerHTML = ''
        }
      }

      return tmp.innerHTML
    },
    setFontSize(size) {
      if (size) {
        this.editor.chain().focus().setFontSize(size).run()
      } else {
        this.editor.chain().focus().unsetFontSize().run()
      }
    },
    setFontColor(color) {
      this.editor.chain().focus().setColor(color).run()
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run()
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run()
    },
    toggleUnderline() {
      this.editor.chain().focus().toggleUnderline().run()
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run()
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run()
    },
    undo() {
      this.editor.chain().focus().undo().run()
    },
    redo() {
      this.editor.chain().focus().redo().run()
    },
    link() {
      // TODO: Translation File
      const linkURL = prompt('Welke URL wil je achter de tekst steken')
      this.editor.chain().focus().toggleLink({ href: linkURL }).run()
    },
    unlink() {
      this.editor.chain().focus().unsetLink().run()
    },
    clearFormatting() {
      this.editor.chain().focus().clearNodes().unsetAllMarks().run()
    },
    alignText(alignment) {
      this.editor.chain().focus().setTextAlign(alignment).run()
    },
    async pasteTextFromClipboard() {
      const text = await navigator.clipboard.readText()
      this.editor.chain().focus().insertContent(text).run()
    },
    appendContent(content) {
      this.editor.chain().focus().insertContent(content).run()
    },
  },
}
</script>

<style lang="scss">
.editor {
  display: flex;
  flex-direction: column;
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;

  &__header {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    flex-wrap: wrap;
    background-color: #ecf0f1;
    border-bottom: 1px solid #ddd;
  }

  &__content {
    padding: 1.25rem 1rem;
    flex: 1 1 auto;
    overflow-x: hidden;
  }
}

.editor-loading {
  pointer-events: none;

  .editor__content {
    &:before {
      content: 'aan het laden...';
      position: relative;
      top: 0;
      left: 0;
    }
  }
}

.editor-divider {
  width: 1px;
  height: 3rem;
  background-color: rgba(#000, 0.1);
  margin: 0 0.75rem 0 0.5rem;
}

.editor-dropdown-button {
  height: 3.5rem;
  color: #0d0d0d;
  border: none !important;
  padding: 0.5rem 1rem;
  background: transparent !important;
  box-shadow: none !important;
  margin-right: 0.25rem;
}

/* Basic editor styles */
.ProseMirror {
  outline: none;
  min-height: 200px;
  max-height: 500px;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  mark {
    background-color: #faf594;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  hr {
    margin: 1rem 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  ul[data-type='taskList'] {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }
}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
  word-break: normal;
}

/* Render the username above the caret */
.collaboration-cursor__label {
  border-radius: 3px 3px 3px 0;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  left: -1px;
  line-height: normal;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}
</style>
