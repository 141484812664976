<template>
  <div>
    <SignUpStatus :momentId="momentId" />

    <div class="programs panel">
      <div class="panel-heading">
        <h4>Geselecteerd voor uitzending</h4>
      </div>
      <table class="table">
        <tbody>
          <SignUpRow v-for="signup in contacted" v-bind="signup" :key="signup.id" :momentId="momentId" />
        </tbody>
      </table>
    </div>

    <div class="programs panel">
      <div class="panel-heading">
        <h4>Willekeurig gekozen</h4>
      </div>
      <table class="table">
        <tbody>
          <SignUpRow v-for="signup in selected" v-bind="signup" :key="signup.id" :momentId="momentId" />
        </tbody>
      </table>
    </div>

    <div class="programs panel">
      <div class="panel-heading">
        <div class="row">
          <h4 class="col-sm-8">Aangemelde luisteraars ({{ count }})</h4>
          <div class="col-sm-4">
            <div class="input-group">
              <span class="input-group-addon"><i class="fa fa-search" /></span>
              <input v-model="query" type="search" class="form-control" placeholder="Zoeken..." />
            </div>
          </div>
        </div>
      </div>
      <table class="table">
        <tbody>
          <SignUpRow v-for="signup in signUpList" v-bind="signup" :key="signup.id" :momentId="momentId" />
        </tbody>
        <tfoot>
          <tr>
            <td colspan="7">
              <ApiPagination
                :totalPages="signUpMoment.totalPages"
                :currentPage="signUpMoment.currentPage"
                @changePage="$store.dispatch('SignUps/fetchSignups', { momentId, page: $event })"
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ApiPagination from '@/general/components/ApiPagination.vue'
import SignUpStatus from './SignUpStatus.vue'
import SignUpRow from './SignUpRow.vue'

export default {
  name: 'SignUps',
  components: {
    ApiPagination,
    SignUpStatus,
    SignUpRow,
  },
  props: ['momentId'],
  data() {
    return {
      searchTimeout: null,
    }
  },
  computed: {
    ...mapState('SignUps', {
      signUps: (state) => state.signUps,
    }),
    signUpMoment() {
      return this.signUps[this.momentId] ? this.signUps[this.momentId] : { signUps: [] }
    },
    count() {
      return this.signUpMoment.signupCount
    },
    signUpList() {
      return this.signUpMoment.signUps.filter((signup) => !signup.selectedAt && !signup.contactedAt)
    },
    contacted() {
      return this.signUpMoment.signUps.filter((signup) => signup.selectedAt && signup.contactedAt)
    },
    selected() {
      return this.signUpMoment.signUps.filter((signup) => signup.selectedAt && !signup.contactedAt)
    },
    query: {
      get() {
        return this.signUpMoment?.params?.query
      },
      set(value) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = setTimeout(() => {
          this.$store.dispatch('SignUps/fetchSignups', { momentId: this.momentId, params: { page: 1, query: value } })
        }, 300)
      },
    },
  },
  mounted() {
    this.$store.dispatch('SignUps/subscribe', this.momentId)
    this.$store.dispatch('SignUps/fetchSignups', { momentId: this.momentId, page: 1 })
  },
}
</script>
