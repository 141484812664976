<template>
  <div>
    <div v-if="loadingImages || images" class="image-selector">
      <div class="image-selector__current">
        <h6>Current Image</h6>
        <img v-if="currentImage" :src="currentImage" alt="Current Image" :style="{ opacity: newImage ? 0.25 : 1 }" />
        <em v-else>This {{ resourceName }} does not have an image yet. Choose one on the right.</em>
      </div>
      <div class="image-selector__images">
        <h6>Select different image</h6>
        <div v-if="loadingImages" class="loading">
          <p><i class="fa fa-spinner fa-spin" /> Loading...</p>
        </div>
        <div v-else class="image-selector__images__options">
          <div
            v-for="image in images"
            :key="image"
            class="image-selector__images__options__image"
            :class="{ selected: newImage === image }"
            @click="toggleNewImage(image)"
          >
            <img :src="image" />
          </div>
        </div>
        <div
          class="image-selector__images__options__image image-selector__images__options__image--custom"
          :class="{ selected: newImage === 'custom' }"
          @click="toggleNewImage('custom')"
        >
          <div>
            <strong>Custom Image</strong>
            <input
              ref="customFileElement"
              type="file"
              :name="customFileFieldName"
              @change="setCustomImage($event.target.files[0])"
            />
            <img v-if="customImage" :src="customImagePreview" />
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <em>Fill out the {{ resourceName }} name to choose an image.</em>
    </div>

    <input v-if="newImage && newImage !== 'custom'" type="hidden" name="image_url" :value="newImage" />
  </div>
</template>

<script>
export default {
  props: ['resourceName', 'currentImage', 'images', 'loadingImages', 'customFileFieldName'],
  data() {
    return {
      newImage: null,
      customImage: null,
    }
  },
  computed: {
    customImagePreview() {
      return this.customImage ? URL.createObjectURL(this.customImage) : null
    },
  },
  watch: {
    images() {
      this.newImage = null
    },
    newImage(newValue, oldValue) {
      if (oldValue === 'custom') {
        this.customImage = null
        this.$refs.customFileElement.value = ''
      }
    },
  },
  methods: {
    toggleNewImage(image) {
      if (this.newImage === image) {
        this.newImage = null
      } else {
        this.newImage = image
      }
    },
    setCustomImage(file) {
      this.customImage = file
      this.newImage = 'custom'
    },
  },
}
</script>

<style scoped lang="scss">
.image-selector {
  display: flex;
  gap: 1rem;

  h6 {
    margin-top: 0;
    font-weight: bold;
  }

  &__current {
    padding-right: 1rem;
    border-right: 2px solid #ccc;

    max-width: 32rem;

    img {
      max-width: 100%;
      transition: opacity 300ms;
    }
  }

  &__images {
    flex: 1;

    &__options {
      margin: 1rem 0;

      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));

      gap: 1rem;
      flex-wrap: wrap;

      &__image {
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: #fafafa;
        border: 1px solid #ececec;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
        padding: 1rem;

        transition: background 150ms;

        cursor: pointer;

        &.selected {
          border-color: #007bff;
          background: #f0f8ff;
        }

        &:hover:not(.selected) {
          background: #f4f4f4;
        }

        img {
          max-width: 100%;
          max-height: 250px;
        }

        &--custom {
          text-align: center;

          input {
            margin: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
